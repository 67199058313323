.canvas{
    width: 50%;
    min-height: 375px;
    background-color: rgb(23, 13, 21);
    border: 1px solid gray;
    margin: 0 auto;
    border-radius: 15px;
    padding: 60px;
    margin-top: 30px;
    font-size: 24px;
    display: grid;
    place-items: center;
    color: rgb(210, 210, 210);
    box-shadow: 0 10px 20px rgba(23, 13, 21, 0.2);
    word-break: break-word;
  }